$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #00a99d; //
$COLFLAT2: #00a99d; //
$COL3: #ffffff; //
$COLFLAT3: #ffffff; //
$COL4: #1b1b1b; //
$COLFLAT4: #1b1b1b; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #43a047; //
$COLFLAT6: #43a047; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #f2f2f2; //
$COLFLAT10: #f2f2f2; //
$COL11: #f2f2f2; //
$COLFLAT11: #f2f2f2; //
$COL12: rgba(255,255,255,0.2); //
$COLFLAT12: #999999; //
$COL13: #ffffff; //
$COLFLAT13: #ffffff; //
$COL14: #ffffff; //
$COLFLAT14: #ffffff; //
$COL15: #999999; //
$COLFLAT15: #999999; //
$COL16: #999999; //Grey
$COLFLAT16: #999999; //Grey
$COL17: rgba(255,255,255,0.859); //White 80%
$COLFLAT17: #ededed; //White 80%
$COL18: rgba(255,255,255,0.49); //White trans
$COLFLAT18: #bebebe; //White trans
$COL19: rgba(255,255,255,0.302); //
$COLFLAT19: #a6a6a6; //
$COL20: #4573a3; //
$COLFLAT20: #4573a3; //
$COL21: rgba(255,255,255,0.569); //
$COLFLAT21: #c8c8c8; //
$COL22: #ffffff; //
$COLFLAT22: #ffffff; //
$COL23: #ffffff; //
$COLFLAT23: #ffffff; //
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: #ffffff; //
$COLFLAT25: #ffffff; //
$COL26: #ffffff; //
$COLFLAT26: #ffffff; //
$COL27: #ffffff; //
$COLFLAT27: #ffffff; //
$COL28: rgba(255,255,255,0.22); //White 20%
$COLFLAT28: #9c9c9c; //White 20%
$COL29: rgba(0,0,0,0.839); //black scroll
$COLFLAT29: #151515; //black scroll
$COL30: #01756a; //green scroll
$COLFLAT30: #01756a; //green scroll
$COL31: #57c0b3; //Light Green
$COLFLAT31: #57c0b3; //Light Green
$COL32: #eb7a7a; //
$COLFLAT32: #eb7a7a; //
$breakpoints: (
  small: 0,
  medium: 3000px,
  large: 3000px,
  xlarge: 3000px,
  xxlarge: 1936px,
);
$global-width: 600px;
